import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Lock, NoSignal } from '../../../assets/icons';
import Notification from '../../../components/Notification';
import Spinner from '../../../components/Spinner';
import { player as $content } from '../../../content';
import { useChannel } from '../../../contexts/Channel';
import { useNotif } from '../../../contexts/Notification';
import usePrevious from '../../../hooks/usePrevious';
import { clsm } from '../../../utils';
import { useChannelView } from '../contexts/ChannelView';
import { usePlayerContext } from '../contexts/Player';
import { useProfileViewAnimation } from '../contexts/ProfileViewAnimation';
import PlayerViewerStreamActions from './PlayerViewerStreamActions';
import StreamInfo from './StreamInfo';
import StreamVideo from './StreamVideo';
import useFullscreen from './useFullscreen';
import useProfileViewPlayerAnimation from './useProfileViewPlayerAnimation';

const nonDoubleClickableTags = ['img', 'h3', 'button', 'svg', 'path'];
const nonDoubleClickableIds = [
  'volume-range-container',
  'rendition-selector-container'
];

const Player = ({ chatSectionRef, hidePlayer, chatView, isVideoView }) => {
  const { dismissNotif, notifyError } = useNotif();
  const { isSplitView } = useChannelView();
  const { channelData } = useChannel();
  const { isLive, isViewerBanned } = channelData || {};
  const isChannelDataAvailable = !!channelData;

  /* Refs */
  const playerSectionRef = useRef();
  const bannedRef = useRef();
  const offlineRef = useRef();
  const spinnerRef = useRef();

  /* IVS Player */
  const {
    mobileClickHandler,
    player: {
      hasError,
      hasPlayedFinalBuffer,
      isLoading,
      videoAspectRatio,
      videoRef
    },
    setShouldKeepOverlaysVisible
  } = usePlayerContext();
  const [isPlayerLoading, setIsPlayerLoading] = useState(isLoading);
  const [shouldShowStream, setShouldShowStream] = useState(
    isLive !== false || hasPlayedFinalBuffer === false
  );
  const isStreamVideoVisible = shouldShowStream;
  const isStreamSpinnerVisible =
    shouldShowStream && isPlayerLoading && !isViewerBanned;
  const isStreamOfflineVisible = !shouldShowStream;
  const isStreamViewerBannedVisible = isViewerBanned;
  const isVideoVisible = shouldShowStream && !isPlayerLoading;

  /* Controls */
  const [openPopupIds, setOpenPopupIds] = useState([]);
  const isPopupOpen = !!openPopupIds.length;

  const prevIsPopupOpen = usePrevious(isPopupOpen);

  /* Profile view player animation */
  const {
    chatAnimationControls,
    disableProfileViewAnimation,
    enableProfileViewAnimation,
    getProfileViewAnimationProps,
    isProfileViewAnimationRunning,
    isProfileViewExpanded,
    playerAnimationControls,
    shouldAnimateProfileView,
    toggleChat
  } = useProfileViewAnimation();
  const visiblePlayerAspectRatio = isVideoVisible ? videoAspectRatio : 4 / 3;
  const playerProfileViewAnimationProps = useMemo(
    () =>
      getProfileViewAnimationProps(playerAnimationControls, {
        expanded: { borderRadius: 24, top: 340, y: 0 },
        collapsed: {
          borderRadius: 0,
          top: '50%',
          y: '-50%',
          stacked: { top: 0, y: 0 }
        }
      }),
    [getProfileViewAnimationProps, playerAnimationControls]
  );

  let targetPlayerRef = videoRef;
  if (isStreamSpinnerVisible) targetPlayerRef = spinnerRef;
  else if (isStreamViewerBannedVisible) targetPlayerRef = bannedRef;
  else if (isStreamOfflineVisible) targetPlayerRef = offlineRef;
  useProfileViewPlayerAnimation({
    chatSectionRef,
    hasPlayedFinalBuffer,
    isVideoVisible,
    playerSectionRef,
    targetPlayerRef,
    visiblePlayerAspectRatio
  });

  /* Fullscreen */
  const { isFullscreenEnabled, onClickFullscreenHandler } =
    useFullscreen(playerSectionRef);

  /* Handlers */
  const onClickPlayerHandler = useCallback(
    (event) => {
      const { target } = event;

      // This condition ensures that the first tap on mobile closes any open popup before closing the controls with a second tap
      if (event.detail === 1 && prevIsPopupOpen && !isPopupOpen) {
        return setOpenPopupIds([]);
      } else if (event.detail === 1) {
        mobileClickHandler();
      } else if (
        event.detail === 2 &&
        !nonDoubleClickableTags.includes(target.tagName.toLowerCase()) &&
        !nonDoubleClickableIds.includes(target.id)
      ) {
        onClickFullscreenHandler(event);
      }
    },
    [
      isPopupOpen,
      mobileClickHandler,
      onClickFullscreenHandler,
      prevIsPopupOpen,
      setOpenPopupIds
    ]
  );

  /* Effects */

  // Disable the animation if we have not yet fetched channel data or if fullscreen is enabled; enable otherwise
  useEffect(() => {
    if (isChannelDataAvailable && !isFullscreenEnabled) {
      enableProfileViewAnimation();
    } else {
      disableProfileViewAnimation();
    }
  }, [
    disableProfileViewAnimation,
    enableProfileViewAnimation,
    isChannelDataAvailable,
    isFullscreenEnabled
  ]);

  // Delay player state transitions until after the animation has finished
  useEffect(() => {
    if (!isProfileViewAnimationRunning) {
      setIsPlayerLoading(isLoading);
      setShouldShowStream(isLive !== false || hasPlayedFinalBuffer === false);
    }
  }, [hasPlayedFinalBuffer, isProfileViewAnimationRunning, isLive, isLoading]);

  // Show chat when stream goes offline in landscape split view
  useEffect(() => {
    if (
      isSplitView &&
      !isProfileViewExpanded &&
      !isProfileViewAnimationRunning &&
      hasPlayedFinalBuffer
    ) {
      toggleChat({ isExpandedNext: false, skipAnimation: true });
    }
  }, [
    hasPlayedFinalBuffer,
    isProfileViewAnimationRunning,
    isProfileViewExpanded,
    isSplitView,
    toggleChat
  ]);

  // Trigger an error notification when there is an error loading the stream
  useEffect(() => {
    if (hasError) {
      notifyError($content.notification.error.error_loading_stream, {
        withTimeout: false
      });
    } else dismissNotif();
  }, [dismissNotif, hasError, notifyError]);

  // Keep the player overlays visible if a popup is open (e.g. volume or rendition setting popup)
  useEffect(() => {
    setShouldKeepOverlaysVisible(isPopupOpen);
  }, [setShouldKeepOverlaysVisible, isPopupOpen]);

  return (
    <motion.section
      {...getProfileViewAnimationProps(chatAnimationControls, {
        expanded: { height: '100%', stacked: { height: '100vh' } },
        collapsed: { height: '100%' }
      })}
      className={clsm([
        'relative',
        'flex',
        'flex-col',
        'justify-center',
        'items-center',
        'max-h-screen',
        'h-auto',
        'md:h-full',
        'z-[100]',
        'transition-colors',
        'overflow-hidden',
        'bg-neutral-900',
        'lg:aspect-[4/3]',
        !isVideoView && 'lg:pr-6',
        'min-w-full',
        'lg:min-w-0',
        'md:self-end',
        chatView && '!hidden',
        shouldAnimateProfileView.current ? 'duration-[400ms]' : 'duration-0'
      ])}
      ref={playerSectionRef}
    >
      <motion.div
        className={clsm([
          'flex',
          'relative',
          'items-center',
          'justify-center',
          'aspect-[4/3]',
          !isVideoView && 'border-2',
          !isVideoView && 'border-white',
          'w-full',
          'h-auto',
          !isVideoView && 'sm:w-[80%]',
          !isVideoView && 'md:w-[60%]',
          'lg:w-full',
          !isVideoView && 'lg:rounded-3xl'
        ])}
      >
        <motion.div
          className={clsm([
            'w-full',
            'h-full',
            'overflow-hidden',
            'relative',
            'lg:rounded-3xl',
            'lg:border-[20px]',
            'lg:border-neutral-900',
            hidePlayer && '!invisible'
          ])}
        >
          <StreamVideo
            ref={videoRef}
            /* Player */
            isPlayerLoading={isPlayerLoading}
            isVisible={isStreamVideoVisible}
            onClickPlayerHandler={onClickPlayerHandler}
            /* Controls */
            openPopupIds={openPopupIds}
            setOpenPopupIds={setOpenPopupIds}
            /* Fullscreen */
            isFullscreenEnabled={isFullscreenEnabled}
            onClickFullscreenHandler={onClickFullscreenHandler}
            /* Profile View Animation */
            playerProfileViewAnimationProps={playerProfileViewAnimationProps}
            isVideoView={isVideoView}
          />
          <StreamInfo
            isVisible={isStreamSpinnerVisible}
            playerProfileViewAnimationProps={playerProfileViewAnimationProps}
            ref={spinnerRef}
            icon={<Spinner size="large" variant="light" />}
          />
          <StreamInfo
            ref={offlineRef}
            isVisible={isStreamOfflineVisible}
            playerProfileViewAnimationProps={playerProfileViewAnimationProps}
            message={$content.stream_offline}
            icon={<NoSignal className={clsm(['fill-white'])} />}
          />
          <StreamInfo
            ref={bannedRef}
            isVisible={isStreamViewerBannedVisible}
            playerProfileViewAnimationProps={playerProfileViewAnimationProps}
            message={$content.you_are_banned}
            icon={<Lock className={clsm(['fill-white'])} />}
          />
        </motion.div>

        {!isVideoView && (
          <PlayerViewerStreamActions
            isPopupOpen={isPopupOpen}
            onClickPlayerHandler={onClickPlayerHandler}
            shouldShowStream={shouldShowStream}
          />
        )}
      </motion.div>

      {!isVideoView && <Notification className="sticky" />}
    </motion.section>
  );
};

Player.propTypes = {
  chatSectionRef: PropTypes.shape({ current: PropTypes.object }).isRequired,
  // eslint-disable-next-line react/require-default-props
  hidePlayer: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  chatView: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  isVideoView: PropTypes.bool
};

export default Player;
