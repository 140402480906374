import { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { channelAPI } from '../../../../api';
import { useUser } from '../../../../contexts/User';
import { FormColor } from './FormColor';
import { FormEmail } from './FormEmail';
import { FormInteract } from './FormInteract';
import { FormUsername } from './FormUsername';

const forcedPassword = 'Password123!';

const RegisterUser = () => {
  const { isSessionValid } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    isSessionValid && navigate('/stream');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [user, handleUser] = useState({
    avatar: 0,
    color: null,
    textColor: null,
    email: null,
    username: null,
    password: forcedPassword
  });
  const [registerState, handleRegisterState] = useState('Interact');

  const handleInteractSubmit = () => {
    handleRegisterState('Email');
  };

  const handleEmailAddressSubmit = ({ email }) => {
    handleUser({ ...user, email });
    handleRegisterState('Color');
  };

  const handleColorSubmit = ({ color, text }) => {
    handleUser({ ...user, color, textColor: text });
    handleRegisterState('Username');
  };

  const handleUsernameSubmit = ({ username }) => {
    handleUser({ ...user, username });
    handleRegisterState('Submit');
  };

  const handleSubmit = useCallback(async () => {
    registerState === 'Submit' &&
      user.username &&
      (await channelAPI.register(user).then(async (response) => {
        const { result: signInResult } = await channelAPI.signIn(user);
        if (signInResult) {
          window.location.reload(true);
          navigate('/stream');
        }
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerState, user]);

  useEffect(() => {
    registerState === 'Submit' && handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerState]);

  const Form = useMemo(() => {
    return {
      Interact: <FormInteract onFormSubmit={handleInteractSubmit} />,
      Email: <FormEmail onFormSubmit={handleEmailAddressSubmit} />,
      Color: <FormColor onFormSubmit={handleColorSubmit} />,
      Username: (
        <FormUsername
          onFormSubmit={handleUsernameSubmit}
          color={user.textColor}
        />
      )
    }[registerState];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerState]);

  return <>{!isSessionValid && Form}</>;
};

export default RegisterUser;
