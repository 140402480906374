import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { TwitchPlayerNonInteractive } from 'react-twitch-embed';
import { useChannel } from '../../../contexts/Channel';
import { clsm } from '../../../utils';
import { useChannelView } from '../contexts/ChannelView';
import { usePlayerContext } from '../contexts/Player';
import { useProfileViewAnimation } from '../contexts/ProfileViewAnimation';
import Controls from './Controls/Controls';
import PlayerOverlay from './PlayerOverlay';

const StreamVideo = forwardRef(
  (
    {
      isFullscreenEnabled,
      isPlayerLoading,
      isVisible,
      onClickFullscreenHandler,
      onClickPlayerHandler,
      openPopupIds,
      playerProfileViewAnimationProps,
      setOpenPopupIds,
      isVideoView
    },
    ref
  ) => {
    const {
      showCaptions,
      setShowCaptions,
      showInitialMute,
      showBackstage,
      setShowBackstage
    } = useChannelView();
    const { isProfileViewExpanded, runningAnimationIds } =
      useProfileViewAnimation();
    const { isOverlayVisible, onMouseMoveHandler } = usePlayerContext();
    const { channelData: { selectedQualityName, isViewerBanned } = {} } =
      useChannel();
    const isPlayerAnimationRunning = runningAnimationIds.includes('player');
    const areControlsContained = !!(
      isProfileViewExpanded ^ isPlayerAnimationRunning
    );
    const shouldShowControlsOverlay =
      isOverlayVisible && !isPlayerAnimationRunning && !isViewerBanned;

    const handleBackstage = () => {
      setShowBackstage(true);

      setTimeout(() => {
        setShowBackstage(false);
      }, 10000);
    };

    return (
      <>
        {showCaptions && (
          <TwitchPlayerNonInteractive
            showCaptions={true}
            autoplay={true}
            muted={false}
            className={clsm(
              'absolute',
              'w-full',
              '!h-full',
              'object-cover',
              'transition-colors',
              'bg-transparent',
              'z-10'
            )}
            channel="playgamechangers"
          />
        )}
        {showBackstage && (
          <iframe
            title="backstage"
            src="/backstage?videoView=true"
            className={clsm(
              // 'absolute',
              'w-full',
              '!h-full',
              'object-cover',
              'transition-colors',
              'bg-transparent'
            )}
          />
        )}
        <motion.video
          {...playerProfileViewAnimationProps}
          className={clsm(
            'absolute',
            'w-full',
            '!h-full',
            'object-cover',
            'transition-colors',
            '-z-10',
            'bg-transparent',

            (showCaptions ||
              showBackstage ||
              isPlayerLoading ||
              isViewerBanned) &&
              '!hidden'
          )}
          muted
          controls
          playsInline
          ref={ref}
        />

        {!isVideoView && (
          <motion.div
            {...playerProfileViewAnimationProps}
            className={clsm([
              'absolute',
              !areControlsContained && ['!h-full'],
              !isVisible && '!hidden'
            ])}
            onClick={onClickPlayerHandler}
            onMouseMove={onMouseMoveHandler}
            role="toolbar"
          >
            <PlayerOverlay
              {...(areControlsContained && {
                className: 'before:rounded-b-3xl'
              })}
              isVisible={shouldShowControlsOverlay || showInitialMute}
            >
              <div className="absolute font-bold font-heading text-white bottom-6 right-4 z-10">
                <button onClick={() => setShowCaptions(!showCaptions)}>
                  Access CC
                </button>
              </div>
              {!showInitialMute && (
                <div className="absolute font-bold font-heading text-white top-2 right-4 z-10">
                  {!showBackstage && (
                    <>
                      {!showCaptions && (
                        <button onClick={handleBackstage}>
                          See the studio
                        </button>
                      )}
                    </>
                  )}
                </div>
              )}
              <Controls
                areControlsContained={areControlsContained}
                openPopupIds={openPopupIds}
                selectedQualityName={selectedQualityName}
                setOpenPopupIds={setOpenPopupIds}
              />
            </PlayerOverlay>
          </motion.div>
        )}
      </>
    );
  }
);

StreamVideo.propTypes = {
  isFullscreenEnabled: PropTypes.bool,
  isPlayerLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClickFullscreenHandler: PropTypes.func.isRequired,
  onClickPlayerHandler: PropTypes.func.isRequired,
  openPopupIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  playerProfileViewAnimationProps: PropTypes.object.isRequired,
  setOpenPopupIds: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  isVideoView: PropTypes.bool
};

StreamVideo.defaultProps = {
  isFullscreenEnabled: false,
  isPlayerLoading: false,
  isVisible: false
};

export default StreamVideo;
