import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/InputButton';

// eslint-disable-next-line react/prop-types
export const FormInteract = ({ onFormSubmit }) => {
  const onSubmit = () => onFormSubmit();
  const navigate = useNavigate();

  return (
    <div className="text-white md:px-32 w-[100%]">
      <label className="text-4xl md:text-[4vw] font-bold" htmlFor="email">
        Would you like to interact?
      </label>
      <p className="text-lg md:text-[1.75vw] leading-normal my-8 md:my-14 md:w-[80%]">
        Game Changers is an interactive show. Please sign up so you can vote and
        change the direction of the story.
      </p>
      <div className="w-[100%] text-center md:text-left space-x-8">
        <Button type="submit" variant="darkGreenOutline" onClick={onSubmit}>
          YES
        </Button>

        <Button
          type="submit"
          variant="redOutline"
          onClick={() => navigate('/stream')}
        >
          NO
        </Button>
      </div>
    </div>
  );
};
