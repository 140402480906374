/* eslint-disable react/prop-types */
// import { fontEmphasis } from '@/utils';

const color = {
  blue: {
    background: 'bg-blue',
    border: 'border-blue',
    color: 'text-black',
    hover: 'enabled:hover:brightness-75'
  },
  purple: {
    background: 'bg-purple',
    border: 'border-purple',
    color: 'text-black',
    hover: 'enabled:hover:brightness-75'
  },
  pink: {
    background: 'bg-pink',
    border: 'border-pink',
    color: 'text-black',
    hover: 'enabled:hover:brightness-75'
  },
  orange: {
    background: 'bg-orange',
    border: 'border-orange',
    color: 'text-black',
    hover: 'enabled:hover:brightness-75'
  },
  green: {
    background: 'bg-green',
    border: 'border-green',
    color: 'text-black',
    hover: 'enabled:hover:brightness-75'
  },
  yellow: {
    background: 'bg-yellow',
    border: 'border-yellow',
    color: 'text-black',
    hover: 'enabled:hover:brightness-75'
  },
  darkGreen: {
    background: 'bg-dark-green',
    border: 'border-dark-green',
    color: 'text-white',
    hover: 'enabled:hover:brightness-75'
  },
  darkGreenOutline: {
    background: 'bg-transparent',
    border: 'border-white',
    color: 'text-dark-green',
    hover: 'enabled:hover:bg-dark-green'
  },
  redOutline: {
    background: 'bg-transparent',
    border: 'border-white',
    color: 'text-red',
    hover: 'enabled:hover:bg-red'
  }
};

export const Button = ({
  variant,
  children,
  onClick,
  isDisabled,
  removeTab,
  small,
  removeMargin,
  colorOverride,
  className
}) => {
  return (
    <button
      tabIndex={removeTab ? -1 : 0}
      disabled={isDisabled}
      onClick={onClick}
      className={`
      ${
        small
          ? 'px-5 py-3 lg:px-[15px] lg:py-[10px] text-xl lg:text-[20px]'
          : 'px-5 py-3 lg:px-[30px] lg:py-[20px] text-xl lg:text-[30px]'
      }
       ${
         isDisabled && 'disabled:opacity-50'
       } font-light transition uppercase appearance-none border-2 ${
        removeMargin
          ? 'lg:rounded-tl-xl lg:rounded-none rounded-r-3xl'
          : 'rounded-3xl'
      } 
      ${
        colorOverride
          ? `${colorOverride} border-none text-black enabled:hover:text-black enabled:hover:brightness-75`
          : `${color[variant].color} ${color[variant].background} ${color[variant].border} ${color[variant].hover} enabled:hover:text-black`
      }
    ${!removeMargin && 'mb-6'}
    ${className}`}
    >
      {children}
    </button>
  );
};
