const OUTER_INPUT_BASE_CLASSES = ['flex', 'relative', 'w-full', 'min-w-[90px]'];

export const OUTER_INPUT_VARIANT_CLASSES = {
  vertical: [...OUTER_INPUT_BASE_CLASSES, 'flex-col', 'justify-center'],
  horizontal: [
    ...OUTER_INPUT_BASE_CLASSES,
    'flex-grow',
    'flex-row',
    'items-center',
    'justify-start'
  ]
};

export const INNER_INPUT_VARIANT_CLASSES = [
  '[&>input:not([type="password"]),&>input:placeholder-shown]:overflow-hidden',
  'flex-col',
  'flex',
  'item-start',
  'items-start',
  'relative',
  'w-full',
  'text-black'
];

export const INPUT_BASE_CLASSES = [
  'appearance-none',
  'border-none',
  'duration-[0.5ms]',
  'ease-in-out',
  'focus:outline-none',
  'font-body',
  'font-bold',
  'min-h-[50px]',
  'min-w-[90px]',
  'placeholder:text-neutral-700',
  'p-4',
  'read-only:cursor-auto',
  'text-[15px]',
  'text-black',
  'transition-all',
  'w-full'
];

export const TEXTAREA_BASE_CLASSES = [
  'appearance-none',
  'border-none',
  'duration-[0.5ms]',
  'ease-in-out',
  'focus:outline-none',
  'font-body',
  'font-bold',
  'lg:min-h-[180px]',
  'min-w-[90px]',
  'placeholder:text-neutral-700',
  'read-only:cursor-auto',
  'text-[15px]',
  'text-black',
  'transition-all',
  'w-full',
  'bg-[#D9D9D9]',
  'rounded-3xl',
  'lg:rounded-none',
  'pr-20',
  'lg:pr-0'
];

export const INPUT_ERROR_CLASSES = ['shadow-[#E34646]', 'shadow-focus'];

export const INPUT_TYPE_CLASSES = {
  text: [
    ...INPUT_BASE_CLASSES,
    'placeholder-shown:overflow-hidden',
    'placeholder-shown:text-ellipsis',
    'placeholder-shown:whitespace-nowrap'
  ],
  password: INPUT_BASE_CLASSES,
  number: INPUT_BASE_CLASSES
};
