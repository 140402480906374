import PropTypes from 'prop-types';
import { useRef } from 'react';

import { FactCheck } from '../../../../assets/icons';
import { STREAM_ACTION_NAME } from '../../../../constants';
import { streamManager as $streamManagerContent } from '../../../../content';
import { useStreamManagerActions } from '../../../../contexts/StreamManagerActions';
import { clsm } from '../../../../utils';
import StreamManagerActionButton from './StreamManagerActionButton';
import { Quiz } from './StreamManagerActionForms';

const STREAM_MANAGER_ACTION_ICONS = {
  [STREAM_ACTION_NAME.QUIZ]: FactCheck
};

export const STREAM_MANAGER_ACTION_MODAL_FORMS = {
  [STREAM_ACTION_NAME.QUIZ]: <Quiz />
};

const StreamManagerActions = ({ className }) => {
  const { openStreamManagerActionModal, sendStreamAction } =
    useStreamManagerActions();
  const streamManagerActionButtonRefsMap = useRef(new Map());
  const lastFocusedStreamManagerActionButtonRef = useRef();

  const getAvailableStreamActions = (streamActionNames) => {
    return Object.values(streamActionNames).reduce((acc, streamActionName) => {
      acc.push(streamActionName);

      return acc;
    }, []);
  };

  const streamActions = getAvailableStreamActions(STREAM_ACTION_NAME);

  return (
    <section
      className={clsm([
        'auto-grid-row',
        'auto-rows-max',
        'bg-neutral-900',
        'dark:bg-darkMode-gray-dark',
        'gap-5',
        'grid-cols-2',
        'grid',
        'h-full',
        'md:grid-cols-4',
        'md:max-w-full',
        'max-w-[351px]',
        'overflow-auto',
        'p-5',
        'rounded-3xl',
        'scrollbar-mb-4',
        'scrollbar-mt-4',
        'sm:grid-rows-none',
        'supports-overlay:overflow-overlay',
        'w-full',
        'xs:grid-cols-3',
        className
      ])}
    >
      {streamActions.map((actionName) => {
        const hasModal = actionName in STREAM_MANAGER_ACTION_MODAL_FORMS;
        const $content =
          $streamManagerContent.stream_manager_actions[actionName];
        const defaultLabel = actionName.replace(/_/g, ' ');
        const ariaLabel = hasModal
          ? `Open the ${defaultLabel} stream action editor`
          : `Trigger a ${defaultLabel} stream action`;
        const label = {
          default: $content.default_label,
          active: $content.active_label
        };

        const onClick = () => {
          if (hasModal) {
            lastFocusedStreamManagerActionButtonRef.current =
              streamManagerActionButtonRefsMap.current.get(actionName);

            openStreamManagerActionModal(actionName, {
              content: {
                actionName,
                confirmText: $content.confirm_text,
                streamManagerActionContent:
                  STREAM_MANAGER_ACTION_MODAL_FORMS[actionName],
                title: ''
              },
              lastFocusedElement: lastFocusedStreamManagerActionButtonRef
            });
          } else sendStreamAction(actionName, undefined, false);
        };

        return (
          <>
            <StreamManagerActionButton
              ariaLabel={ariaLabel}
              icon={STREAM_MANAGER_ACTION_ICONS[actionName]}
              key={actionName}
              label={label}
              name={actionName}
              text={'What should the team do?'}
              onClick={onClick}
              ref={(el) =>
                streamManagerActionButtonRefsMap.current.set(actionName, el)
              }
            />
          </>
        );
      })}
    </section>
  );
};

StreamManagerActions.defaultProps = {
  className: ''
};

StreamManagerActions.propTypes = {
  className: PropTypes.string
};

export default StreamManagerActions;
