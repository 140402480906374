import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { STREAM_ACTION_NAME } from '../../../constants';
import { useViewerStreamActions } from '../../../contexts/ViewerStreamActions';
import QuizViewerStreamAction from '../ViewerStreamActions/QuizCard';
import { useProfileViewAnimation } from '../contexts/ProfileViewAnimation';

const PlayerViewerStreamActions = ({
  isPopupOpen,
  onClickPlayerHandler,
  shouldShowStream
}) => {
  const {
    currentViewerStreamActionData,
    currentViewerStreamActionName,
    setCurrentViewerAction,
    shouldRenderActionInTab
  } = useViewerStreamActions();
  const { chatAnimationControls, getProfileViewAnimationProps } =
    useProfileViewAnimation();

  return (
    <motion.div
      {...getProfileViewAnimationProps(chatAnimationControls, {
        expanded: { opacity: 0 },
        collapsed: { opacity: 1 }
      })}
      className={isPopupOpen ? '-z-10' : ''}
    >
      <AnimatePresence>
        {currentViewerStreamActionName === STREAM_ACTION_NAME.QUIZ &&
          !shouldRenderActionInTab && (
            <QuizViewerStreamAction
              {...currentViewerStreamActionData}
              shouldShowStream={shouldShowStream}
              setCurrentViewerAction={setCurrentViewerAction}
              shouldRenderActionInTab={shouldRenderActionInTab}
            />
          )}
      </AnimatePresence>
    </motion.div>
  );
};

PlayerViewerStreamActions.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  onClickPlayerHandler: PropTypes.func.isRequired,
  shouldShowStream: PropTypes.bool
};

PlayerViewerStreamActions.defaultProps = { shouldShowStream: false };

export default PlayerViewerStreamActions;
