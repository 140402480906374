import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { createAnimationProps } from '../../../helpers/animationPropsHelper';
import { clsm } from '../../../utils';

const POSITION = { TOP: 'top', BOTTOM: 'bottom' };

const PlayerOverlay = ({ children, className, isVisible, position }) => (
  <motion.div
    {...createAnimationProps({
      animations: ['fadeIn-full'],
      options: { isVisible }
    })}
    className={clsm([
      'absolute',
      'flex',
      'h-full',
      'left-0',
      'w-full',
      'lg:rounded-none',
      !isVisible && 'pointer-events-none',
      position === POSITION.TOP && ['top-0', 'pt-8', 'md:pt-4', 'items-start'],
      position === POSITION.BOTTOM && ['bottom-0', 'items-end'],
      className
    ])}
  >
    <div className={clsm(['relative', 'w-full', 'h-full'])}>{children}</div>
  </motion.div>
);

PlayerOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  position: PropTypes.oneOf(Object.values(POSITION))
};

PlayerOverlay.defaultProps = {
  className: '',
  position: 'bottom'
};

export default PlayerOverlay;
