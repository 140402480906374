import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import * as R from 'ramda';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { channelAPI } from '../../../api';
import { postPollAnswerAction } from '../../../api/channel';
import Button from '../../../components/Button';
import { useResponsiveDevice } from '../../../contexts/ResponsiveDevice';
import { useUser } from '../../../contexts/User';
import { createAnimationProps } from '../../../helpers/animationPropsHelper';
import { clsm } from '../../../utils';
import ProgressBar from './ProgressBar';
import {
  defaultSlideUpVariant,
  defaultViewerStreamActionTransition
} from './viewerStreamActionsTheme';

const defaultQuizAnswerHeight = 140;

const QuizCard = ({
  answers,
  color,
  correctAnswerIndex,
  duration,
  question,
  setCurrentViewerAction,
  shouldRenderActionInTab,
  shouldShowStream,
  startTime,
  id
}) => {
  const { userData, isSessionValid } = useUser();
  const [answerHeight, setAnswerHeight] = useState(defaultQuizAnswerHeight);
  const [isAnswerSelected, setIsAnswerSelected] = useState();
  const [chosenAnswerIndex, setChosenAnswerIndex] = useState();
  const [options, setOptions] = useState({});
  const [totalAnswers, setTotalAnswers] = useState(0);
  const quizButtonArrRef = useRef([]);
  const { username } = useParams();
  const { isDesktopView } = useResponsiveDevice();

  const audioSelect = new Audio('/select.mp3');
  const audioPopup = new Audio('/popup.mp3');

  const clickPoll = () => {
    audioSelect.play();
  };

  const profileColorButtonClassNames = clsm([
    'disabled:text-white',
    'focus:shadow-black',
    'text-black',
    'bg-[#D9D9D9]',
    'text-[20px]',
    'text-left',
    '!justify-start',
    '!items-start',
    'py-5'
  ]);

  const selectAnswer = (index) => {
    clickPoll();
    postPollAnswerAction({
      channel: username,
      pollId: id,
      idx: index
    });
    setIsAnswerSelected(true);
    setChosenAnswerIndex(index);
  };

  const getTotal = R.pipe(R.values, R.reduce(R.mergeWith(R.add), {}));

  const getAnswers = useCallback(async () => {
    const data = await channelAPI.getPollAnswers(username, id);
    const total = getTotal(data.options);

    if (data) {
      setOptions(data.options);
      setTotalAnswers(total.votes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAnswerSelected) {
      const poll = setInterval(() => getAnswers(), 1000);

      return () => clearInterval(poll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnswerSelected]);

  useEffect(() => {
    if (userData?.username === 'stream') {
      const poll = setInterval(() => getAnswers(), 1000);

      return () => clearInterval(poll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.username]);

  useLayoutEffect(() => {
    quizButtonArrRef.current.forEach((quizButton) => {
      if (quizButton.clientHeight > answerHeight) {
        setAnswerHeight(quizButton.clientHeight);
      }
    });
  }, [answerHeight]);

  useEffect(() => {
    audioPopup.play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={clsm([
        'lg:absolute',
        'self-start',
        'flex-col',
        'flex',
        'h-full',
        'no-scrollbar',
        'overflow-x-hidden',
        'overflow-y-auto',
        'supports-overlay:overflow-y-overlay',
        'w-full',
        'bottom-0',
        'left-0',
        'h-auto',
        'justify-end',
        'lg:pr-6',
        'z-10'
      ])}
    >
      <motion.div
        {...createAnimationProps({
          animations: ['fadeIn-full'],
          customVariants: defaultSlideUpVariant,
          transition: defaultViewerStreamActionTransition,
          options: { shouldAnimate: !shouldRenderActionInTab }
        })}
        className={clsm([
          'bg-opacity-80',
          `bg-black`,
          'flex',
          'lg:items-center',
          'w-full',
          'px-5',
          'lg:px-0',
          'mb-5',
          'lg:pt-[16px]',
          'lg:pb-[25px]',
          'lg:px-[40px]',
          'relative',
          'flex-col',
          'lg:flex-row',
          'lg:ml-5'
        ])}
      >
        <ProgressBar color={color} duration={duration} startTime={startTime} />
        <div className="lg:pl-[40px] w-full">
          <h3
            className={clsm([
              'flex',
              'w-full',
              'break-word',
              'text-white',
              'lg:text-[30px]',
              'text-[16px]',
              'mb-4'
            ])}
          >
            {question}
          </h3>
          <div className="flex flex-col lg:flex-row w-full lg:space-x-4 space-y-4 lg:space-y-0">
            {answers.map((answer, index) => {
              const background = () => {
                switch (index) {
                  case 0: {
                    return '#E37546';
                  }
                  case 1: {
                    return '#5CB7CB';
                  }
                  case 2: {
                    return '#82E346';
                  }
                  case 3: {
                    return '#DC56F2';
                  }
                  default:
                    break;
                }
              };

              const percentage = (options[index]?.votes / totalAnswers) * 100;

              return (
                <Button
                  variant="secondary"
                  key={`answer-${index}`}
                  ariaLabel={`answer ${index + 1}`}
                  customStyles={
                    !isDesktopView
                      ? {
                          background: `linear-gradient(90deg, ${background()} ${
                            options[index]?.votes > 0 && percentage
                          }%, 0, #d2d2d2)`
                        }
                      : {
                          background: `linear-gradient(0deg, ${background()} ${
                            options[index]?.votes > 0 && percentage
                          }%, 0, #d2d2d2)`
                        }
                  }
                  className={clsm([
                    profileColorButtonClassNames,
                    'lg:w-[25%]',
                    'w-full',
                    'whitespace-normal',
                    'lg:h-[140px]',
                    'h-auto',
                    'break-anywhere',
                    '!text-black',
                    '!font-bold',
                    'relative',
                    'text-[80%]',
                    'lg:text-[90%]',
                    'xl:text-[140%]',
                    'border-solid',
                    'border-4',
                    'border-black',
                    isAnswerSelected === true &&
                      chosenAnswerIndex === index && [
                        'animate-pulse',
                        `!border-white`
                      ]
                  ])}
                  onClick={() => selectAnswer(index)}
                  isDisabled={isAnswerSelected === true || !isSessionValid}
                  ref={(el) => (quizButtonArrRef.current[index] = el)}
                >
                  {answer}
                  {userData?.isAdmin && (
                    <span className="text-gray text-4xl absolute bottom-2 right-2">
                      {!isNaN(percentage) &&
                        `${percentage.toFixed(1).replace(/[.,]0$/, '')}%`}
                    </span>
                  )}
                </Button>
              );
            })}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

QuizCard.defaultProps = {
  answers: [],
  color: 'default',
  correctAnswerIndex: 0,
  duration: 10,
  shouldRenderActionInTab: false,
  shouldShowStream: false
};

QuizCard.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.string),
  color: PropTypes.string,
  correctAnswerIndex: PropTypes.number,
  duration: PropTypes.number,
  question: PropTypes.string.isRequired,
  setCurrentViewerAction: PropTypes.func.isRequired,
  shouldRenderActionInTab: PropTypes.bool,
  shouldShowStream: PropTypes.bool,
  startTime: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired
};

export default QuizCard;
