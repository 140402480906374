import { MotionConfig } from 'framer-motion';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider
} from 'react-router-dom';

// Context Providers
import { Provider as ChannelProvider } from './contexts/Channel';
import { Provider as ChatMessagesProvider } from './contexts/ChatMessages';
import { Provider as LastFocusedElementProvider } from './contexts/LastFocusedElement';
import { Provider as ModalProvider } from './contexts/Modal';
import { Provider as NotificationProvider } from './contexts/Notification';
import { Provider as ResponsiveDeviceProvider } from './contexts/ResponsiveDevice';
import { Provider as StreamsProvider } from './contexts/Streams';
import { Provider as TooltipsProvider } from './contexts/Tooltips';
import { Provider as UserProvider } from './contexts/User';
import { Provider as ViewerStreamActionsProvider } from './contexts/ViewerStreamActions';

// Pages
import {
  Channel,
  Polls,
  Settings,
  StreamHealth,
  StreamManager,
  UserManagement
} from './pages';

// UserManagement Subpages
import { RegisterUser, SigninUser } from './pages/UserManagement/subpages';

// Page Layouts
import { AppLayoutWithNavbar, RequireAuth } from './layouts';
import RequireAdmin from './layouts/RequireAdmin';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <MotionConfig reducedMotion="user">
          <LastFocusedElementProvider>
            <ResponsiveDeviceProvider>
              <NotificationProvider>
                <ModalProvider>
                  <TooltipsProvider>
                    <UserProvider />
                  </TooltipsProvider>
                </ModalProvider>
              </NotificationProvider>
            </ResponsiveDeviceProvider>
          </LastFocusedElementProvider>
        </MotionConfig>
      }
    >
      <Route
        element={
          <StreamsProvider>
            <ChannelProvider>
              <ChatMessagesProvider>
                <AppLayoutWithNavbar />
              </ChatMessagesProvider>
            </ChannelProvider>
          </StreamsProvider>
        }
      >
        {/* PUBLIC PAGES - UGC */}
        <Route index element={<Navigate replace to="/register" />} />
        <Route path=":username">
          <Route element={<ViewerStreamActionsProvider />}>
            <Route index element={<Channel />} />
            <Route path="video" element={<Channel />} />
            <Route path="eco" element={<Channel />} />
          </Route>
        </Route>

        {/* PRIVATE PAGES */}
        <Route element={<RequireAuth />}>
          <Route element={<RequireAdmin />}>
            <Route path="manager/settings" element={<Settings />} />
            <Route path="manager" element={<StreamManager />} />
            <Route path="stream/polls" element={<Polls />} />
            <Route path="health">
              <Route index element={<StreamHealth />} />
              <Route path=":streamId" element={<StreamHealth />} />
              <Route path="*" element={<Navigate replace to="/health" />} />
            </Route>
          </Route>
        </Route>
      </Route>

      {/* PUBLIC PAGES - User Management */}
      <Route element={<UserManagement />}>
        <Route path="register" element={<RegisterUser />} />
        <Route path="manager/login" element={<SigninUser />} />
      </Route>
    </Route>
  )
);

const App = () => <RouterProvider router={router} />;

export default App;
