/* eslint-disable react/prop-types */
import { forwardRef, useRef, useState } from 'react';
import { channelAPI } from '../../api';
import { Button } from '../../components/InputButton';
import Tabs from '../../components/Tabs/Tabs';
import { clsm } from '../../utils';

const PollButton = ({ question }) => {
  const [checked, handleChecked] = useState(false);

  const handleClick = async () => {
    await channelAPI
      .sendStreamAction({
        message: question.message,
        options: question.options,
        duration: question.duration
      })
      .then(() => handleChecked(true));
  };

  return (
    <Button variant={checked ? 'green' : 'blue'} small onClick={handleClick}>
      {question?.title}
    </Button>
  );
};

const PollsControlCenter = forwardRef(() => {
  const webBroadcastParentContainerRef = useRef();

  return (
    <>
      <div
        ref={webBroadcastParentContainerRef}
        className={clsm(['flex', 'h-full', 'w-full', 'max-w-[960px]'])}
      >
        <Tabs
          className={clsm([
            '[&>div]:px-0',
            '[&>div]:pt-0',
            '[&>div>button]:h-9'
          ])}
        >
          <div
            className={clsm([
              'gap-6',
              'grid-cols-[351px,auto]',
              'grid',
              'grow',
              'h-full',
              'md:grid-cols-none',
              'md:grid-rows-[min-content,minmax(200px,100%)]',
              'max-w-[960px]',
              'w-full'
            ])}
          >
            <div
              className={clsm([
                'overflow-hidden',
                'h-full',
                'relative',
                'w-full',
                'my-10'
              ])}
            >
              <h1 className="text-5xl text-white font-bold mb-10">PART A</h1>
              <h2 className="text-3xl text-white font-bold mb-5">Poll Text</h2>
              <div className="flex flex-wrap mb-8 space-x-4">
                {episodeOneTest.map((q, i) => (
                  <PollButton question={q} key={i} />
                ))}
              </div>
              <h2 className="text-3xl text-white font-bold mb-5">Chapter 1</h2>
              <div className="flex flex-wrap mb-8 space-x-4">
                {episodeOneChapterOne.map((q, i) => (
                  <PollButton question={q} key={i} />
                ))}
              </div>
              <h2 className="text-3xl text-white font-bold mb-5">Chapter 2</h2>
              <div className="flex flex-wrap mb-8 space-x-4">
                {episodeOneChapterTwo.map((q, i) => (
                  <PollButton question={q} key={i} />
                ))}
              </div>
              <h2 className="text-3xl text-white font-bold mb-5">Chapter 3</h2>
              <div className="flex flex-wrap mb-8 space-x-4">
                {episodeOneChapterThree.map((q, i) => (
                  <PollButton question={q} key={i} />
                ))}
              </div>
              <h1 className="text-5xl text-white font-bold mb-10">PART B</h1>
              <h2 className="text-3xl text-white font-bold mb-5">Poll Text</h2>
              <div className="flex flex-wrap mb-8 space-x-4">
                {episodeTwoTest.map((q, i) => (
                  <PollButton question={q} key={i} />
                ))}
              </div>
              <h2 className="text-3xl text-white font-bold mb-5">Chapter 4</h2>
              <div className="flex flex-wrap mb-8 space-x-4">
                {episodeTwoChapterFour.map((q, i) => (
                  <PollButton question={q} key={i} />
                ))}
              </div>
              <h2 className="text-3xl text-white font-bold mb-5">Chapter 5</h2>
              <div className="flex flex-wrap mb-8 space-x-4">
                {episodeTwoChapterFive.map((q, i) => (
                  <PollButton question={q} key={i} />
                ))}
              </div>
            </div>
          </div>
        </Tabs>
      </div>
    </>
  );
});

export default PollsControlCenter;

const episodeOneTest = [
  {
    title: 'POLL TEST',
    message: 'How informed are you about climate change?',
    options: [
      'Very informed',
      'Fairly informed',
      'Not very informed',
      'I know nothing about it'
    ],
    duration: 45
  }
];

const episodeOneChapterOne = [
  {
    title: 'POLL 1',
    message: 'What should the team do?',
    options: ['Protest projection', 'Provide backup power'],
    duration: 45
  },
  {
    title: 'OVERLAY PUZZLE 1',
    message: 'Pick an answer',
    options: ['A', 'B', 'C', 'D'],
    duration: 45
  },
  {
    title: 'OVERLAY PUZZLE 2',
    message: 'Pick an answer',
    options: ['A', 'B', 'C', 'D'],
    duration: 45
  }
];

const episodeOneChapterTwo = [
  {
    title: 'POLL 2',
    message: 'How should GreenView Rising raise awareness with a campaign?',
    options: [
      'GWEN - Champion tech innovation',
      'JAMIE - Enlighten individuals about greenwashing',
      'REECE - Hold corporations to account'
    ],
    duration: 45
  }
];

const episodeOneChapterThree = [
  {
    title: 'POLL 6',
    message: 'Who should infiltrate Terracore?',
    options: ['REECE - Charm receptionist', 'GWEN - Climb through air vents'],
    duration: 45
  },
  {
    title: 'OVERLAY PUZZLE 3',
    message: 'Pick an answer',
    options: ['A', 'B', 'C', 'D'],
    duration: 45
  },
  {
    title: 'OVERLAY PUZZLE 4',
    message: 'Pick an answer',
    options: ['Orange', 'Blue', 'Green', 'Pink'],
    duration: 45
  }
];

const episodeTwoTest = [
  {
    title: 'POLL TEST',
    message: 'Did you watch PART A yesterday?',
    options: ['Yes - all of it', 'Yes - a bit of it', 'No'],
    duration: 45
  }
];

const episodeTwoChapterFour = [
  {
    title: 'POLL 8',
    message: 'How should the team respond to Jamie?',
    options: ['Forgive Jamie', 'Send Jamie Packing'],
    duration: 45
  }
];

const episodeTwoChapterFive = [
  {
    title: 'POLL 9',
    message: 'What should they wear?',
    options: ['Cyber outfit', 'Military outfit', 'Scuba outfit'],
    duration: 45
  },
  {
    title: 'POLL 10',
    message: 'What should GreenView Rising do?',
    options: ['Take the money', 'Decline the money'],
    duration: 45
  }
];
