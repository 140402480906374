import { useForm } from 'react-hook-form';
import { Button } from '../../../../components/InputButton';

// eslint-disable-next-line react/prop-types
export const FormEmail = ({ onFormSubmit }) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => onFormSubmit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-[100%]">
      <div className="text-white md:px-32 w-[100%]">
        <label className="text-4xl md:text-[4vw] font-bold" htmlFor="email">
          Enter your email address:
        </label>
        <p className="text-lg md:text-[1.75vw] leading-normal my-8 md:my-14 md:w-[80%]">
          Your email allows you to interact in the game. It will not be used for
          any other purpose than to communicate with you about this game.
        </p>
        <div>
          <input
            {...register('email')}
            id="email"
            type="email"
            required={true}
            className="w-[100%] md:w-[80%] mb-10 md:mb-20 md:mb-14 px-8 py-4 md:px-6 md:py-2 text-[20px] md:text-[40px] rounded-2xl bg-[#d9d9d9] text-black"
          />
        </div>
        <div className="w-[100%] text-center md:text-left">
          <Button type="submit" variant="darkGreenOutline">
            OK
          </Button>
        </div>
      </div>
    </form>
  );
};
