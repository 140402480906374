import PropTypes from 'prop-types';
import { forwardRef, useEffect, useRef } from 'react';

import { useUser } from '../../contexts/User';
import { clsm } from '../../utils';
import {
  TEXTAREA_BASE_CLASSES as innerInputVariantClasses,
  INPUT_ERROR_CLASSES as inputErrorClasses,
  INPUT_TYPE_CLASSES as inputTypeClasses,
  OUTER_INPUT_VARIANT_CLASSES as outerInputVariantClasses
} from '../Input/InputTheme';
import { Button } from '../InputButton';

const Textarea = forwardRef(
  (
    {
      onKeyDown,
      ariaLabel,
      autoCapitalize,
      autoComplete,
      autoCorrect,
      className,
      customStyles,
      description,
      error,
      footer,
      isRequired,
      label,
      name,
      onBlur,
      onChange,
      onClick,
      onFocus,
      placeholder,
      readOnly,
      tabIndex,
      type: initialType,
      value,
      variant,
      min,
      max
    },
    ref
  ) => {
    const hideDescription = useRef(false);
    const { userData } = useUser();

    const outerInputClasses = clsm(
      variant,
      outerInputVariantClasses[variant],
      initialType === 'number' && ['w-auto'],
      'p-3',
      'lg:p-0'
    );

    const innerInputClasses = clsm(innerInputVariantClasses);

    const inputClasses = clsm(
      inputTypeClasses[initialType],
      error !== undefined && error !== null && inputErrorClasses,
      className
    );

    useEffect(() => {
      hideDescription.current = hideDescription.current || !!error;
    }, [error]);

    return (
      <div className="relative">
        <div className={outerInputClasses}>
          <div
            id={`${name}-input-container`}
            style={customStyles}
            className={innerInputClasses}
          >
            <textarea
              ref={ref}
              {...(onChange ? { onChange } : {})}
              {...(onClick ? { onClick } : {})}
              {...(onFocus ? { onFocus } : {})}
              {...(onBlur ? { onBlur } : {})}
              {...(tabIndex ? { tabIndex } : {})}
              {...(min ? { min } : {})}
              {...(max ? { max } : {})}
              onKeyDown={onKeyDown}
              aria-disabled={readOnly}
              aria-label={ariaLabel}
              autoCapitalize={autoCapitalize}
              autoComplete={autoComplete}
              autoCorrect={autoCorrect}
              className={inputClasses}
              id={name}
              initial-type={initialType}
              name={name}
              placeholder={placeholder}
              readOnly={readOnly}
              required={isRequired}
              style={
                initialType === 'password' && value
                  ? { paddingRight: '52px' }
                  : {}
              }
              type={initialType}
              value={value}
              maxLength={300}
            />
          </div>
        </div>
        <div className="absolute bottom-0 right-0 p-3 lg:p-0 h-full lg:h-auto">
          <Button
            type="submit"
            removeMargin
            small
            colorOverride={`bg-[${userData?.color}]`}
            className="h-full"
          >
            Send
          </Button>
        </div>
      </div>
    );
  }
);

Textarea.defaultProps = {
  onKeyDown: undefined,
  ariaLabel: '',
  autoCapitalize: 'none',
  autoComplete: 'on',
  autoCorrect: 'off',
  className: '',
  customStyles: {},
  description: '',
  error: null,
  footer: undefined,
  isRequired: true,
  label: '',
  onBlur: null,
  onChange: null,
  onClick: null,
  onFocus: null,
  placeholder: '',
  readOnly: false,
  tabIndex: null,
  type: 'text',
  value: '',
  variant: 'vertical',
  min: 0,
  max: 100
};

Textarea.propTypes = {
  onKeyDown: PropTypes.func,
  ariaLabel: PropTypes.string,
  autoCapitalize: PropTypes.string,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.string,
  className: PropTypes.string,
  customStyles: PropTypes.object,
  description: PropTypes.string,
  error: PropTypes.string,
  footer: PropTypes.node,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  tabIndex: PropTypes.number,
  type: PropTypes.oneOf(['text', 'password', 'number']),
  value: PropTypes.string,
  variant: PropTypes.oneOf(['vertical', 'horizontal']),
  min: PropTypes.number,
  max: PropTypes.number
};

export default Textarea;
