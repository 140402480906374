import PropTypes from 'prop-types';

import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useUser } from '../../../contexts/User';
import { clsm } from '../../../utils';

const ProgressBar = ({ duration, startTime }) => {
  const now = Date.now();
  const difference = ((now - startTime) / 1000).toFixed(0);
  const [counter, setCounter] = useState(duration - difference);
  const [isFinished, setIsFinished] = useState(false);
  const { userData } = useUser();

  useEffect(() => {
    counter === 0 && setIsFinished(true);
  }, [counter]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    return () => clearInterval(timer);
  }, [counter]);

  const commonClasses = [
    'rounded-full',
    'flex',
    'items-center',
    'justify-center'
  ];

  return (
    <AnimatePresence>
      {isFinished && !userData?.isAdmin ? (
        <div
          className={clsm([
            commonClasses,
            'text-white',
            'md:text-[30px]',
            'text-[20px]',
            'absolute',
            'z-100',
            'h-full',
            'top-0',
            'rounded-none',
            'w-full',
            'z-10',
            `bg-black`,
            '-ml-10'
          ])}
        >
          <motion.div
            initial={{
              background: 'linear-gradient(90deg, #5C46E3 0%, 0, #d2d2d2)'
            }}
            animate={{
              background: 'linear-gradient(90deg, #5C46E3 100%, 0, #d2d2d2)'
            }}
            transition={{
              duration: 10,
              ease: 'linear'
            }}
            className="self-start md:self-center md:mt-0 mt-6 text-black text-lg font-bold font-heading progress-processing w-full max-w-[500px] rounded-3xl text-center py-2 mx-3"
          >
            PROCESSING
          </motion.div>
        </div>
      ) : (
        <div className={clsm(['m:pt-0', 'pt-5', 'md:pb-0', 'pb-5'])}>
          <div
            className={clsm([
              commonClasses,
              'h-[75px]',
              'lg:h-[100px]',
              'lg:w-[100px]',
              'w-[75px]',
              'progress',
              'transition-background',
              'border-transparent',
              'lg:border-[20px]',
              'border-[15px]'
            ])}
            style={{
              background: `linear-gradient(white, white) content-box no-repeat, conic-gradient(#FBFE78 ${
                (counter / duration) * 100
              }%, 0, #d2d2d2 ) border-box`
            }}
          >
            <h1
              className={clsm([
                commonClasses,
                'bg-black',
                'lg:h-[60px]',
                'lg:w-[60px]',
                'h-[45px]',
                'w-[45px]',
                'text-white',
                'lg:text-[30px]',
                'text-[20px]'
              ])}
            >
              {counter}
            </h1>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

ProgressBar.defaultProps = {
  duration: 10
};

ProgressBar.propTypes = {
  duration: PropTypes.number,
  startTime: PropTypes.number.isRequired
};

export default ProgressBar;
