import {
  QUIZ_DATA_KEYS,
  STREAM_ACTION_NAME,
  STREAM_MANAGER_ACTION_LIMITS
} from '../../constants';

export const DEFAULT_STREAM_MANAGER_ACTIONS_STATE = {
  [STREAM_ACTION_NAME.QUIZ]: {
    [QUIZ_DATA_KEYS.QUESTION]: '',
    [QUIZ_DATA_KEYS.ANSWERS]: Array(
      STREAM_MANAGER_ACTION_LIMITS[STREAM_ACTION_NAME.QUIZ][
        QUIZ_DATA_KEYS.ANSWERS
      ].min
    ).fill(''),
    [QUIZ_DATA_KEYS.CORRECT_ANSWER_INDEX]: 0,
    [QUIZ_DATA_KEYS.DURATION]: 15
  }
};
