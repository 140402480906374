export const BUTTON_OUTLINE_CLASSES = [
  'focus:outline-none',
  'focus:shadow-focus',
  'shadow-black'
];

export const BUTTON_BASE_CLASSES = [
  '[&>svg]:fill-white',
  'border-none',
  'cursor-pointer',
  'dark:[&>svg]:fill-white',
  'disabled:cursor-auto',
  'disabled:pointer-events-none',
  'duration-[0.15s]',
  'ease-in-out',
  'flex',
  'h-[44px]',
  'items-center',
  'justify-center',
  'min-w-[98px]',
  'px-6',
  'py-3',
  'rounded-3xl',
  'select-none',
  'transition',
  'w-auto',
  'whitespace-nowrap',
  ...BUTTON_OUTLINE_CLASSES
];

const TEXT_BASE_CLASSES = [
  'bg-transparent',
  '[&>.spinner]:text-white',
  'text-[#5CB7CB]'
];

const TEXT_BASE_HOVER_CLASSES = [
  'hover:bg-gray-hover',
  'hover:bg-gray-light-hover'
];

export const BUTTON_HOVER_CLASSES = {
  primary: ['hover:brightness-75'],
  secondary: ['hover:brightness-75'],
  tertiary: ['hover:brightness-75'],
  destructive: ['hover:brightness-75'],
  icon: ['hover:brightness-75'],
  primaryText: [
    ...TEXT_BASE_HOVER_CLASSES,
    'focus:brightness-75',
    'hover:brightness-75'
  ],
  secondaryText: TEXT_BASE_HOVER_CLASSES,
  tertiaryText: [...TEXT_BASE_HOVER_CLASSES, 'hover:brightness-75']
};

export const BUTTON_VARIANT_CLASSES = {
  primary: [
    '[&>svg]:fill-white',
    'bg-[#5CB7CB]',
    'focus:bg-[#5CB7CB]',
    'text-white'
  ],
  secondary: [
    '[&>.spinner]:text-white',
    '[&>svg]:fill-white',
    'bg-white',
    'focus:bg-white',
    'text-black'
  ],
  tertiary: ['[&>svg]:fill-white', 'bg-gray', 'focus:bg-gray', 'text-white'],
  destructive: [
    '[&>.spinner]text-white',
    '[&>svg]:fill-white',
    'bg-[#E34646]',
    'text-white',
    'focus:bg-[#E34646]',
    'text-white'
  ],
  icon: [
    '[&>svg:disabled]:fill-lightMode-gray-dark',
    '[&>svg:focus]:fill-white',
    '[&>svg]:fill-white',
    '[&>svg]:fill-lightMode-gray-dark',
    'bg-transparent',
    '[&>svg:disabled]:fill-white',
    '[&>svg:focus]:fill-white',
    '[&>svg]:fill-white',
    'focus:brightness-75',
    'focus:brightness-75',
    'h-auto',
    'min-w-[auto]',
    'p-2.5',
    'rounded-[50%]'
  ],
  primaryText: [
    ...TEXT_BASE_CLASSES,
    '[&.spinner]:text-white',
    '[&>svg]:fill-[#5CB7CB]',
    'focus:brightness-75',
    'text-[#5CB7CB]'
  ],
  secondaryText: [
    ...TEXT_BASE_CLASSES,
    'dark:text-white',
    'text-white',
    '[&>.spinner]:text-white',
    '[&>svg]:fill-white',
    'dark:text-white'
  ],
  tertiaryText: [
    ...TEXT_BASE_CLASSES,
    '[&.spinner]:text-white',
    '[&>svg]:fill-white',
    'space-x-3',
    '!justify-start',
    'px-3',
    'py-1.5',
    'round-lg',
    'rounded-lg',
    'text-white',
    'text-[#5CB7CB]'
  ]
};

export const BUTTON_LINK_CLASSES = [
  'border-none',
  'm-0, py-3, px-6',
  'outline-none'
];
