import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  VolumeHigh as VolumeHighSvg,
  VolumeLow as VolumeLowSvg,
  VolumeMedium as VolumeMediumSvg
} from '../../../../assets/icons';
import { VOLUME_MAX, VOLUME_MEDIAN, VOLUME_MIN } from '../../../../constants';
import { useResponsiveDevice } from '../../../../contexts/ResponsiveDevice';
import useClickAway from '../../../../hooks/useClickAway';
import { clsm } from '../../../../utils';
import { useChannelView } from '../../contexts/ChannelView';
import { usePlayerContext } from '../../contexts/Player';
import { CONTROLS_BUTTON_BASE_CLASSES } from './ControlsTheme';

export const POPUP_ID = 'volume';

const VolumeSetting = ({
  className,
  isDisabled,
  isExpanded,
  setOpenPopupIds,
  updateVolume,
  volumeLevel
}) => {
  const [muted, setMuted] = useState(volumeLevel === VOLUME_MIN);
  const [volume, setVolume] = useState(volumeLevel);
  const { subscribeOverlayElement, stopPropagAndResetTimeout } =
    usePlayerContext();
  const settingsButtonRef = useRef();
  const volumeContainerRef = useRef();
  const { showCaptions, showInitialMute, setShowInitialMute } =
    useChannelView();

  const { isTouchscreenDevice } = useResponsiveDevice();

  const subscribeOverlayControl = useCallback(
    (element) => {
      subscribeOverlayElement(element);
      settingsButtonRef.current = element;
    },
    [subscribeOverlayElement]
  );

  const closeVolumeContainer = useCallback(() => {
    setOpenPopupIds((prev) => {
      if (prev.includes(POPUP_ID)) return prev.filter((id) => id !== POPUP_ID);

      return prev;
    });
  }, [setOpenPopupIds]);

  const volumeSettingHandler = useCallback(
    (event) => {
      if (!isTouchscreenDevice) {
        stopPropagAndResetTimeout(event);
        setOpenPopupIds((prev) => {
          if (!prev.includes(POPUP_ID)) return [...prev, POPUP_ID];
          if (event.type !== 'mouseleave') return prev;
          return prev.filter((id) => id !== POPUP_ID);
        });
      }
    },
    [isTouchscreenDevice, setOpenPopupIds, stopPropagAndResetTimeout]
  );

  useClickAway([volumeContainerRef, settingsButtonRef], closeVolumeContainer);

  const getVolumeSVG = useCallback(() => {
    if (volumeLevel === VOLUME_MIN) {
      return <VolumeLowSvg />;
    } else if (volumeLevel < VOLUME_MEDIAN && volumeLevel < VOLUME_MAX) {
      return <VolumeMediumSvg />;
    } else {
      return <VolumeHighSvg />;
    }
  }, [volumeLevel]);

  /*
   * Toggling the mute button will initiate a state change in the volume level,
   * resulting in it being set to either the minimum volume or the value of the previous volume level.
   */

  const toggleMute = useCallback(
    (event) => {
      setMuted((prevState) => !prevState);
      updateVolume((muted && volume) || VOLUME_MIN);
      stopPropagAndResetTimeout(event);
    },
    [muted, stopPropagAndResetTimeout, updateVolume, volume]
  );

  const toggleUnMute = useCallback(
    (event) => {
      setMuted(false);
      updateVolume(100);
      setShowInitialMute(false);
      stopPropagAndResetTimeout(event);
    },
    [stopPropagAndResetTimeout, updateVolume, setShowInitialMute]
  );

  useEffect(() => {
    if (volumeLevel > VOLUME_MIN) {
      setMuted(false);
      setVolume(volumeLevel);
    }
    if (showCaptions) {
      setMuted(true);
      updateVolume(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volumeLevel, showCaptions]);

  useEffect(() => {
    updateVolume(100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showInitialMute && (
        <button
          onClick={() => toggleUnMute()}
          className="text-white absolute w-full h-full bg-black bg-opacity-75 flex justify-center items-center flex-col"
        >
          <VolumeHighSvg className="fill-white w-[80px]" />
          <span>Unmute audio</span>
        </button>
      )}
      {!showInitialMute && (
        <div
          className={clsm(['flex', 'relative', 'items-center', 'pb-4', 'pl-4'])}
          onMouseEnter={volumeSettingHandler}
          onMouseLeave={volumeSettingHandler}
        >
          <div
            className={clsm(isExpanded && ['flex', 'h-[40px]', 'items-end'])}
          >
            <button
              aria-label={`${
                isExpanded ? 'Close' : 'Open'
              } the video volume selector`}
              className={clsm([
                ...CONTROLS_BUTTON_BASE_CLASSES,
                'transition-transform',
                isExpanded && 'border-white',
                className,
                'h-12'
              ])}
              disabled={isDisabled}
              onClick={toggleMute}
              onFocus={volumeSettingHandler}
              ref={subscribeOverlayControl}
            >
              {getVolumeSVG()}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

VolumeSetting.defaultProps = {
  className: '',
  isDisabled: false,
  isExpanded: false,
  volumeLevel: VOLUME_MAX
};

VolumeSetting.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isExpanded: PropTypes.bool,
  setOpenPopupIds: PropTypes.func.isRequired,
  updateVolume: PropTypes.func.isRequired,
  volumeLevel: PropTypes.number
};

export default VolumeSetting;
