import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { channelAPI } from '../../../../api';
import Button from '../../../../components/Button';
import { channel as $channelContent } from '../../../../content';
import { useChannel } from '../../../../contexts/Channel';
import { useChatMessages } from '../../../../contexts/ChatMessages';
import { useResponsiveDevice } from '../../../../contexts/ResponsiveDevice';
import { useUser } from '../../../../contexts/User';
import useResize from '../../../../hooks/useResize';
import useStickyScroll from '../../../../hooks/useStickyScroll';
import { clsm } from '../../../../utils';
import ChatLine from './ChatLine';
import StickScrollButton from './StickScrollButton';

const $content = $channelContent.chat;

const Messages = ({
  isChatPopupOpen,
  isModerator,
  openChatPopup,
  chatView
}) => {
  const { channelData } = useChannel();
  const { username: chatRoomOwnerUsername } = channelData || {};
  const chatRef = useRef();
  const { messages, initMessages } = useChatMessages();
  const [hasInitMessages, setHasInitMessages] = useState(false);
  const { isSticky, scrollToBottom } = useStickyScroll(chatRef, messages);
  const { isMobileView, isLandscape } = useResponsiveDevice();
  const isSplitView = isMobileView && isLandscape;
  const [chatHistory, setChatHistory] = useState();
  const { isSessionValid } = useUser();
  const [showChatHistory, handleShowChatHistory] = useState(!isSessionValid);

  useEffect(() => {
    const fetchChatHistory = async () => {
      const data = await channelAPI.getChatHistory(chatRoomOwnerUsername);

      if (data) {
        const mappedData = data
          .filter((item) => item.type === 'MESSAGE')
          .map((item) => item.payload);
        setChatHistory(mappedData);
      }
    };
    if (showChatHistory) {
      fetchChatHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChatHistory]);

  useEffect(() => {
    if (chatRoomOwnerUsername) {
      initMessages(chatRoomOwnerUsername);
      setHasInitMessages(true);
    }

    return () => setHasInitMessages(false);
  }, [chatRoomOwnerUsername, initMessages]);

  useEffect(() => {
    // Reset the sticky scroll when entering/exiting split view
    // as the scroll position will change between layouts
    setTimeout(scrollToBottom, 10);
  }, [isSplitView, scrollToBottom]);

  useResize(() => {
    // Reset the sticky scroll when resizing browser
    setTimeout(scrollToBottom, 10);
  });

  return (
    <div
      className={clsm([
        'flex',
        'lg:h-full',
        'items-end',
        'justify-center',
        'relative',
        'w-full',
        !chatView ? 'h-[85%]' : 'h-full'
      ])}
    >
      <div
        className={clsm([
          'no-scrollbar',
          'absolute',
          'h-full',
          'w-full',
          'flex',
          'flex-col',
          'items-start',
          !chatView && 'space-y-3',
          !chatView && 'px-[18px]',
          !chatView && 'pt-5',
          'overflow-x-hidden',
          'overflow-y-auto',
          'supports-overlay:overflow-y-overlay',
          'mb-2',
          chatView && 'chat-view-messages'
        ])}
        role="log"
        ref={chatRef}
      >
        {showChatHistory
          ? chatHistory?.length > 0 &&
            chatHistory.map(
              ({
                content: message,
                sendTime,
                id: messageId,
                isDeleted,
                isOwnMessage,
                isPreloaded,
                sender: { attributes: senderAttributes },
                wasDeletedByUser
              }) => {
                const {
                  channelAssetUrls: channelAssetUrlsStr,
                  ...restSenderAttributes
                } = senderAttributes;

                const selectMessageToModerate = () =>
                  openChatPopup({
                    id: messageId,
                    message,
                    ...restSenderAttributes
                  });

                if (isDeleted && (isOwnMessage || wasDeletedByUser))
                  return (
                    <p
                      data-testid="chatline-message-removed"
                      className={clsm(['text-white', 'py-2', 'px-4'])}
                      key={messageId}
                    >
                      {$content.message_removed}
                    </p>
                  );
                else if (isDeleted) return null;

                return (
                  <ChatLine
                    {...(isModerator
                      ? { onClick: selectMessageToModerate }
                      : {})}
                    {...senderAttributes}
                    isFocusable={isModerator && !isChatPopupOpen}
                    key={messageId}
                    message={message}
                    shouldAnimateIn={!isPreloaded}
                    sendTime={sendTime}
                  />
                );
              }
            )
          : !chatView && (
              <div className="absolute flex justify-center w-[calc(100%_-_36px)] text-center top-1">
                <Button
                  className="text-[12px]"
                  variant="secondaryText"
                  onClick={() => handleShowChatHistory(true)}
                >
                  Show history
                </Button>
              </div>
            )}
        {(hasInitMessages ? messages : []).map(
          ({
            content: message,
            sendTime,
            id: messageId,
            isDeleted,
            isOwnMessage,
            isPreloaded,
            sender: { attributes: senderAttributes },
            wasDeletedByUser
          }) => {
            const {
              channelAssetUrls: channelAssetUrlsStr,
              ...restSenderAttributes
            } = senderAttributes;

            const selectMessageToModerate = () =>
              openChatPopup({
                id: messageId,
                message,
                ...restSenderAttributes
              });

            if (isDeleted && (isOwnMessage || wasDeletedByUser))
              return (
                <p
                  data-testid="chatline-message-removed"
                  className={clsm(['text-white', 'py-2', 'px-4'])}
                  key={messageId}
                >
                  {$content.message_removed}
                </p>
              );
            else if (isDeleted) return null;

            return (
              <ChatLine
                {...(isModerator ? { onClick: selectMessageToModerate } : {})}
                {...senderAttributes}
                isFocusable={isModerator && !isChatPopupOpen}
                key={messageId}
                message={message}
                shouldAnimateIn={!isPreloaded}
                sendTime={sendTime}
                chatView={chatView}
              />
            );
          }
        )}
      </div>
      <StickScrollButton isSticky={isSticky} scrollToBottom={scrollToBottom} />
    </div>
  );
};

Messages.defaultProps = { isChatPopupOpen: false, isModerator: false };

Messages.propTypes = {
  isChatPopupOpen: PropTypes.bool,
  isModerator: PropTypes.bool,
  openChatPopup: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  chatView: PropTypes.bool
};

export default Messages;
