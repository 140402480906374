import { useState } from 'react';
import { Button } from '../../../../components/InputButton';
import { clsm } from '../../../../utils';

const colors = [
  { color: '#E34646', bg: 'bg-[#E34646]', text: 'text-[#E34646]' },
  { color: '#E37546', bg: 'bg-[#E37546]', text: 'text-[#E37546]' },
  { color: '#E3C146', bg: 'bg-[#E3C146]', text: 'text-[#E3C146]' },
  { color: '#82E346', bg: 'bg-[#82E346]', text: 'text-[#82E346]' },
  { color: '#46E3DA', bg: 'bg-[#46E3DA]', text: 'text-[#46E3DA]' },
  { color: '#468EE3', bg: 'bg-[#468EE3]', text: 'text-[#468EE3]' },
  { color: '#5C46E3', bg: 'bg-[#5C46E3]', text: 'text-[#5C46E3]' },
  { color: '#8B46E3', bg: 'bg-[#8B46E3]', text: 'text-[#8B46E3]' },
  { color: '#D746E3', bg: 'bg-[#D746E3]', text: 'text-[#D746E3]' },
  { color: '#E35946', bg: 'bg-[#E35946]', text: 'text-[#E35946]' },
  { color: '#E39B46', bg: 'bg-[#E39B46]', text: 'text-[#E39B46]' },
  { color: '#46E372', bg: 'bg-[#46E372]', text: 'text-[#46E372]' },
  { color: '#46BEE3', bg: 'bg-[#46BEE3]', text: 'text-[#46BEE3]' },
  { color: '#4669E3', bg: 'bg-[#4669E3]', text: 'text-[#4669E3]' },
  { color: '#6F46E3', bg: 'bg-[#6F46E3]', text: 'text-[#6F46E3]' },
  { color: '#B146E3', bg: 'bg-[#B146E3]', text: 'text-[#B146E3]' },
  { color: '#E346AE', bg: 'bg-[#E346AE]', text: 'text-[#E346AE]' },
  { color: '#E3467F', bg: 'bg-[#E3467F]', text: 'text-[#E3467F]' }
];

// eslint-disable-next-line react/prop-types
export const FormColor = ({ onFormSubmit }) => {
  const [selectedColor, handleColor] = useState({
    color: '#46e385',
    text: 'text-[#46e385]'
  });

  const onSubmit = () => onFormSubmit(selectedColor);

  const ColorPicker = () => {
    return (
      <div className="mb-10 md:mb-20 mx-auto md:mx-0 flex flex-wrap rounded-2xl overflow-hidden max-w-[315px] md:max-w-[630px]">
        {colors.map((c, i) => (
          <button
            key={i}
            className={clsm([
              c.bg,
              'w-[35px] md:w-[70px] h-[35px] md:h-[70px] m-0 hover:brightness-75',
              selectedColor.color === c.color && 'brightness-75'
            ])}
            onClick={() => handleColor({ color: c.color, text: c.text })}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="text-white md:px-32 w-[100%]">
      <label className="text-4xl md:text-[4vw] font-bold" htmlFor="username">
        Pick a colour:
      </label>
      <p className="text-lg md:text-[1.75vw] leading-normal my-8 md:my-14 md:w-[80%]">
        Your colour will show up in the chat section when you make comments.
      </p>
      <ColorPicker />
      <div className="w-[100%] text-center md:text-left">
        <Button type="submit" variant="darkGreenOutline" onClick={onSubmit}>
          OK
        </Button>
      </div>
    </div>
  );
};
