import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { channelAPI } from '../../../api';
import Form from '../../../components/Form';
import { userManagement as $content } from '../../../content';
import { useNotif } from '../../../contexts/Notification';
import { useUser } from '../../../contexts/User';

const SigninUser = () => {
  const { logOutAction } = useUser();
  const { notifySuccess } = useNotif();
  const navigate = useNavigate();

  useEffect(() => {
    if (logOutAction === 'accountDeletion') {
      notifySuccess($content.notification.success.account_deleted);
    }
  }, [logOutAction, notifySuccess]);

  const onSuccess = () => navigate('/manager');

  return (
    <>
      <Form
        disableValidation
        submitHandler={channelAPI.signIn}
        submitText={$content.sign_in}
        title={$content.login_page.title}
        onSuccess={onSuccess}
        inputsData={{
          username: {},
          password: {
            type: 'password'
          }
        }}
      />
    </>
  );
};

export default SigninUser;
