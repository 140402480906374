import { useForm } from 'react-hook-form';
import { Button } from '../../../../components/InputButton';
import { clsm } from '../../../../utils';

// eslint-disable-next-line react/prop-types
export const FormUsername = ({ onFormSubmit, color }) => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => onFormSubmit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-[100%]">
      <div className="text-white md:px-32 w-[100%]">
        <label className="text-4xl md:text-[4vw] font-bold" htmlFor="username">
          Type your screen name:
        </label>
        <p className="text-lg md:text-[1.75vw] leading-normal my-8 md:my-14 md:w-[80%]">
          This is the name that will be used for you when you chat.
        </p>
        <div>
          <input
            minLength={2}
            maxLength={40}
            className={clsm([
              color ? color : 'text-black',
              'w-[100%]',
              'md:w-[80%]',
              'mb-10',
              'md:mb-20',
              'md:mb-14',
              'px-8',
              'py-4',
              'md:px-6',
              'md:py-2',
              'text-[20px]',
              'md:text-[40px]',
              'rounded-2xl',
              'bg-[#d9d9d9]'
            ])}
            {...register('username')}
            id="username"
            type="text"
            required={true}
          />
        </div>
        <div className="w-[100%] text-center md:text-left">
          <Button type="submit" variant="darkGreenOutline">
            OK
          </Button>
        </div>
      </div>
    </form>
  );
};
