/**
 * MISC
 */
export const NO_DATA_VALUE = '----';

export const CHANNEL_TYPE = {
  BASIC: 'BASIC',
  STANDARD: 'STANDARD',
  ADVANCED_SD: 'ADVANCED_SD',
  ADVANCED_HD: 'ADVANCED_HD'
};

/**
 * APP
 */
export const BREAKPOINTS = { xxs: 0, xs: 331, sm: 576, md: 768, lg: 1225 };

export const MAIN_THEME_COLOR = '#292b32';

export const DEFAULT_NOTIF_TIMEOUT = 3000; // ms

export const CHANNEL_DATA_REFRESH_INTERVAL = 5000; // 5 seconds

/**
 * CHAT
 */
export const BANNED_USERNAME_CHANNEL_ID_SEPARATOR = 'channel/';
export const MAX_RECONNECT_ATTEMPTS = 7;

export const CHAT_LOG_LEVELS = {
  DEBUG: 'debug',
  INFO: 'info',
  ERROR: 'error'
};

/**
 * DEBUG
 */
export const USE_MOCKS = false;

export const RESTRICTED_PROD_CONSOLE_TYPES = ['log', 'info'];

/**
 * EXCEPTIONS
 */
export const ALIAS_EXISTS_EXCEPTION = 'AliasExistsException';
export const EMAIL_EXISTS_EXCEPTION = 'EmailExistsException';
export const LIMIT_EXCEEDED_EXCEPTION = 'LimitExceededException';
export const NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException';
export const RESERVED_USERNAME_EXCEPTION = 'ReservedUsernameException';
export const TOO_MANY_REQUESTS_EXCEPTION = 'TooManyRequestsException';
export const UNEXPECTED_EXCEPTION = 'UnexpectedException';
export const USER_LAMBDA_VALIDATION_EXCEPTION = 'UserLambdaValidationException';
export const USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException';
export const USERNAME_EXISTS_EXCEPTION = 'UsernameExistsException';

export const GENERIC_ERROR_MESSAGE = 'Unexpected Error Occurred';

export const ERROR_KEY_MAP = {
  [USERNAME_EXISTS_EXCEPTION]: {
    type: 'input_error--username',
    contentKey: 'username_taken'
  },
  [RESERVED_USERNAME_EXCEPTION]: {
    type: 'input_error--username',
    contentKey: 'unavailable_username'
  },
  [ALIAS_EXISTS_EXCEPTION]: {
    type: 'input_error--username',
    contentKey: 'username_taken'
  },
  [EMAIL_EXISTS_EXCEPTION]: {
    type: 'input_error--email',
    contentKey: 'unavailable_email'
  },
  [USER_NOT_FOUND_EXCEPTION]: {
    type: 'notification',
    contentKey: 'incorrect_username_or_password'
  },
  [NOT_AUTHORIZED_EXCEPTION]: {
    type: 'notification',
    contentKey: 'incorrect_username_or_password'
  },
  [LIMIT_EXCEEDED_EXCEPTION]: {
    type: 'notification',
    contentKey: 'attempt_limit_exceeded'
  },
  [UNEXPECTED_EXCEPTION]: {
    type: 'notification',
    contentKey: 'unexpected_error_occurred'
  }
};

export const KEY_MAP_REGEX = new RegExp(
  `${Object.keys(ERROR_KEY_MAP).join('|')}`
);

/**
 * CHANNELS
 */
export const USER_MANAGEMENT_THEME_COLOR = '#000000';

export const PROFILE_COLORS = [
  'blue',
  'green',
  'lavender',
  'purple',
  'salmon',
  'turquoise',
  'yellow'
];
export const DEFAULT_PROFILE_VIEW_TRANSITION = { type: 'tween', duration: 0.4 };

export const SUPPORTED_IMAGE_FILE_FORMATS = [['jpg', 'jpeg'], 'png'];

/**
 * STREAM HEALTH
 */
export const STREAM_SESSIONS_REFRESH_INTERVAL = 15000; // 15 seconds
export const STREAM_SESSION_DATA_REFRESH_INTERVAL = 5000; // 5 seconds

export const CONCURRENT_VIEWS = 'ConcurrentViews';
export const INGEST_FRAMERATE = 'IngestFramerate';
export const INGEST_VIDEO_BITRATE = 'IngestVideoBitrate';
export const KEYFRAME_INTERVAL = 'KeyframeInterval';

/**
 * PLAYER
 */
export const VOLUME_MEDIAN = 50;
export const VOLUME_MIN = 0;
export const VOLUME_MAX = 100;

export const MODERATOR_PILL_TIMEOUT = 5000; // ms

export const COMPOSER_MAX_CHARACTER_LENGTH = 500;
export const COMPOSER_RATE_LIMIT_BLOCK_TIME_MS = 2000; // 2 seconds

/**
 * STREAM MANAGER
 */
export const STREAM_ACTION_NAME = {
  QUIZ: 'quiz'
};

export const QUIZ_DATA_KEYS = {
  QUESTION: 'question',
  ANSWERS: 'answers',
  CORRECT_ANSWER_INDEX: 'correctAnswerIndex',
  DURATION: 'duration'
};

export const LOCALSTORAGE_ENABLED_STREAM_ACTIONS = [STREAM_ACTION_NAME.QUIZ];

export const STREAM_MANAGER_ACTION_LIMITS = {
  [STREAM_ACTION_NAME.QUIZ]: {
    [QUIZ_DATA_KEYS.ANSWERS]: {
      min: 1,
      max: 4,
      maxCharLength: 128 // TENTATIVE
    },
    [QUIZ_DATA_KEYS.QUESTION]: { maxCharLength: 256 }, // TENTATIVE
    [QUIZ_DATA_KEYS.DURATION]: { min: 5, max: 30 } // seconds
  }
};

const ADVANCED_DEFAULT_CONFIG = {
  maxBitrate: 3500,
  maxFramerate: 30,
  maxResolution: { width: 1280, height: 720 }
};

const {
  BASIC_FULL_HD_LANDSCAPE,
  BASIC_FULL_HD_PORTRAIT,
  STANDARD_LANDSCAPE,
  STANDARD_PORTRAIT
} = window.IVSBroadcastClient;
export const BROADCAST_STREAM_CONFIG_PRESETS = {
  [CHANNEL_TYPE.BASIC]: {
    landscape: BASIC_FULL_HD_LANDSCAPE,
    portrait: BASIC_FULL_HD_PORTRAIT
  },
  [CHANNEL_TYPE.STANDARD]: {
    landscape: STANDARD_LANDSCAPE,
    portrait: STANDARD_PORTRAIT
  },
  [CHANNEL_TYPE.ADVANCED_SD]: {
    landscape: ADVANCED_DEFAULT_CONFIG,
    portrait: ADVANCED_DEFAULT_CONFIG
  },
  [CHANNEL_TYPE.ADVANCED_HD]: {
    landscape: ADVANCED_DEFAULT_CONFIG,
    portrait: ADVANCED_DEFAULT_CONFIG
  }
};

/**
 * Stream Manager page, Following section
 */
export const STREAM_MANAGER_DEFAULT_TAB = 0;
export const STREAM_MANAGER_WEB_BROADCAST_TAB = 1;
