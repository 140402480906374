import { motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/InputButton';
import PageUnavailable from '../../components/PageUnavailable';
import Tabs from '../../components/Tabs/Tabs';
import { STREAM_ACTION_NAME } from '../../constants';
import { channel as $channelContent } from '../../content';
import { useChannel } from '../../contexts/Channel';
import { Provider as NotificationProvider } from '../../contexts/Notification';
import { useResponsiveDevice } from '../../contexts/ResponsiveDevice';
import { useUser } from '../../contexts/User';
import { useViewerStreamActions } from '../../contexts/ViewerStreamActions';
import useMount from '../../hooks/useMount';
import useResize from '../../hooks/useResize';
import { clsm } from '../../utils';
import Chat from './Chat';
import Player from './Player';
import ProfileViewFloatingNav from './ProfileViewFloatingNav';
import QuizViewerStreamAction from './ViewerStreamActions/QuizCard';
import { useChannelView } from './contexts/ChannelView';
import { Provider as PlayerProvider } from './contexts/Player';
import { useProfileViewAnimation } from './contexts/ProfileViewAnimation';

const DEFAULT_SELECTED_TAB_INDEX = 0;
const CHAT_PANEL_TAB_INDEX = 1;

// eslint-disable-next-line react/prop-types
const Channel = ({ hidePlayer }) => {
  const { isSessionValid } = useUser();
  const navigate = useNavigate();
  const { channelError } = useChannel();
  const { isLandscape, isMobileView } = useResponsiveDevice();
  const { isStackedView, isSplitView } = useChannelView();
  const { getProfileViewAnimationProps, chatAnimationControls } =
    useProfileViewAnimation();
  const {
    currentViewerStreamActionData,
    currentViewerStreamActionName,
    currentViewerStreamActionTitle,
    setCurrentViewerAction,
    shouldRenderActionInTab
  } = useViewerStreamActions();
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    DEFAULT_SELECTED_TAB_INDEX
  );
  const channelRef = useRef();
  const chatSectionRef = useRef();
  const isMounted = useMount();
  const chatViewUrl = new URLSearchParams(useLocation().search).get('chatView');
  const videoViewUrl = new URLSearchParams(useLocation().search).get(
    'videoView'
  );

  const [isChatView, setIsChatView] = useState(false);
  const [isVideoView, setIsVideoView] = useState(false);

  let visibleChatWidth = 360;
  if (isSplitView) visibleChatWidth = 308;
  else if (isStackedView) visibleChatWidth = '100%';

  useEffect(() => {
    setIsChatView(!!chatViewUrl);
    setIsVideoView(!!videoViewUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateChatSectionHeight = useCallback(() => {
    let chatSectionHeight = 200;

    if (isStackedView) {
      /**
       * When switching between mobile landscape and portrait modes, channelWidth may not be accurate.
       * Therefore, we use the window.innerHeight instead; otherwise, we use the channel width.
       */
      const { innerWidth, innerHeight } = window;
      const { clientWidth: channelWidth } = channelRef.current;
      const width = isMobileView ? innerWidth : channelWidth;

      chatSectionHeight = Math.max(innerHeight - (width * 3) / 4, 200); // chat section should be no less than 200px in height
    }

    chatSectionRef.current.style.minHeight = `${chatSectionHeight}px`;
  }, [isMobileView, isStackedView]);

  useResize(updateChatSectionHeight, { shouldCallOnMount: true });

  // Ensures we have computed and set the chat section min-height before the first render
  useLayoutEffect(() => {
    if (!isMounted()) updateChatSectionHeight();
  }, [isMounted, updateChatSectionHeight]);

  if (channelError) return <PageUnavailable />;

  return (
    <PlayerProvider>
      <div
        className={clsm([
          'flex',
          /* Default View */
          'w-full',
          'lg:h-full',
          'lg:flex-row',
          'flex-col',
          'min-h-[100vh]',
          /* Split View */
          isLandscape && [
            'lg:flex-row',
            'lg:h-screen',
            'touch-screen-device:lg:flex-row',
            'touch-screen-device:lg:h-screen',
            'lg:px-0',
            (!isChatView || !isVideoView) && 'px-8',
            (!isChatView || !isVideoView) && 'lg:pt-[50px]',
            isSessionValid ? 'lg:pb-[50px]' : 'lg:pb-[100px]',
            (!isChatView || !isVideoView) && 'lg:px-[25px]',
            isVideoView && '!p-0'
          ]
        ])}
        ref={channelRef}
      >
        <NotificationProvider>
          <Player
            chatSectionRef={chatSectionRef}
            isVideoView={isVideoView}
            chatView={isChatView}
          />
        </NotificationProvider>
        <motion.section
          {...getProfileViewAnimationProps(
            chatAnimationControls,
            {
              desktop: { collapsed: { width: 'auto' }, expanded: { width: 0 } },
              stacked: { width: '100%' },
              split: { collapsed: { width: 308 }, expanded: { width: 0 } }
            },
            {
              visible: { width: visibleChatWidth },
              hidden: { width: 0 }
            }
          )}
          className={clsm([
            'relative',
            'flex',
            'flex-grow',
            'shrink-0',
            'overflow-hidden',
            /* Stacked View */
            'h-full',
            /* Split View */
            isLandscape && [
              'md:min-h-[auto]',
              'touch-screen-device:md:h-screen',
              'touch-screen-device:md:min-h-[auto]'
            ],
            isChatView && '!w-full',
            isVideoView && 'hidden'
          ])}
        >
          <div
            ref={chatSectionRef}
            className={clsm([
              'relative',
              'flex',
              'bg-neutral-900',
              'flex-grow',
              /* Stacked View */
              'md:min-w-full',
              /* Split View */
              isLandscape && [
                !isChatView && 'md:min-w-[308px]',

                !isChatView && 'touch-screen-device:md:min-w-[308px]'
              ]
            ])}
          >
            <Tabs>
              {shouldRenderActionInTab && !isChatView && (
                <>
                  <Tabs.List
                    selectedIndex={selectedTabIndex}
                    setSelectedIndex={setSelectedTabIndex}
                    tabs={[
                      {
                        label: currentViewerStreamActionTitle,
                        panelIndex: 0
                      },
                      { label: $channelContent.tabs.chat, panelIndex: 1 }
                    ]}
                  />
                  <Tabs.Panel index={0} selectedIndex={selectedTabIndex}>
                    {currentViewerStreamActionName ===
                      STREAM_ACTION_NAME.QUIZ && (
                      <QuizViewerStreamAction
                        {...currentViewerStreamActionData}
                        setCurrentViewerAction={setCurrentViewerAction}
                        shouldRenderActionInTab={shouldRenderActionInTab}
                      />
                    )}
                  </Tabs.Panel>
                </>
              )}
              {selectedTabIndex === 0 && shouldRenderActionInTab && (
                <ProfileViewFloatingNav
                  containerClassName="fixed"
                  reverseVisibility
                />
              )}
              <Tabs.Panel
                index={1}
                selectedIndex={
                  shouldRenderActionInTab
                    ? selectedTabIndex
                    : CHAT_PANEL_TAB_INDEX
                }
              >
                <NotificationProvider>
                  <Chat hideMessage={hidePlayer} chatView={isChatView} />
                </NotificationProvider>
              </Tabs.Panel>
            </Tabs>
          </div>
        </motion.section>
      </div>
      <div>
        {!isSessionValid || !isChatView || !isVideoView || (
          <div className="text-center absolute bottom-0 w-full">
            <Button
              small
              variant="darkGreen"
              onClick={() => navigate('/register')}
            >
              Login to interact
            </Button>
          </div>
        )}
      </div>
    </PlayerProvider>
  );
};

export default Channel;
