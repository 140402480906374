import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Button from '../../../../components/Button';
import Tooltip from '../../../../components/Tooltip';
import { useResponsiveDevice } from '../../../../contexts/ResponsiveDevice';
import { clsm } from '../../../../utils';

const ACTIVE_BUTTON_COLORS = [
  'bg-[#5CB7CB]',
  'focus:bg-[#5CB7CB]',
  'hover:brighten-75',
  '[&>svg]:fill-white'
];
const INACTIVE_BUTTON_COLORS = [
  'bg-[#E34646]',
  'focus:bg-[#E34646]',
  'hover:brighten-75',
  '[&>svg]:fill-white'
];

const WebBroadcastControl = forwardRef(({ buttons, isOpen }, ref) => {
  const { isDesktopView, isTouchscreenDevice } = useResponsiveDevice();

  return (
    <div
      className={clsm([
        'flex',
        'justify-center',
        'mt-5',
        'mb-5',
        isOpen || !isDesktopView ? 'space-x-5' : 'space-x-3'
      ])}
    >
      {buttons
        .filter(({ isVisible = true }) => isVisible)
        .map(
          (
            {
              ariaLabel,
              icon,
              isActive,
              isDeviceControl = false,
              isDisabled = false,
              onClick,
              tooltip,
              withRef = undefined
            },
            i
          ) => (
            <Tooltip
              key={`wb-control-tooltip-${tooltip}-${i}`}
              position="above"
              translate={{ y: 2 }}
              message={!isDisabled && tooltip}
            >
              <Button
                ariaLabel={ariaLabel}
                key={`wb-control-btn-${tooltip}-${i}`}
                ref={withRef && ref}
                variant="icon"
                onClick={onClick}
                isDisabled={isDisabled}
                disableHover={isTouchscreenDevice}
                className={clsm([
                  'w-11',
                  'h-11',
                  '[&>svg]:fill-white',
                  isDeviceControl &&
                    !isActive && [
                      INACTIVE_BUTTON_COLORS,
                      isTouchscreenDevice && [
                        'hover:bg-[#E34646]',
                        'dark:hover:dark:bg-[#E34646]'
                      ]
                    ],
                  !isDeviceControl &&
                    isActive && [
                      ACTIVE_BUTTON_COLORS,
                      isTouchscreenDevice && ['hover:bg-[#5CB7CB]']
                    ]
                ])}
              >
                {icon}
              </Button>
            </Tooltip>
          )
        )}
    </div>
  );
});

WebBroadcastControl.propTypes = {
  buttons: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default WebBroadcastControl;
