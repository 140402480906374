import PropTypes from 'prop-types';

import { useChannel } from '../../../../contexts/Channel';
import { clsm } from '../../../../utils';
import { usePlayerContext } from '../../contexts/Player';
import VolumeSetting, {
  POPUP_ID as VOLUME_SETTING_POPUP_ID
} from './VolumeSetting';

const Controls = ({ openPopupIds, setOpenPopupIds }) => {
  const { channelData: { isViewerBanned } = {} } = useChannel();
  const {
    player: { updateVolume, volumeLevel }
  } = usePlayerContext();
  const mobileSVGOpacity = '[&>svg]:fill-white';

  const isVolumeSettingPopupExpanded = !!openPopupIds.find(
    (openPopupId) => openPopupId === VOLUME_SETTING_POPUP_ID
  );

  return (
    <div
      className={clsm([
        'flex',
        'items-end',
        'justify-between',
        'relative',
        'space-x-4',
        'w-full',
        'h-full'
      ])}
    >
      <div
        className={clsm(['flex', 'space-x-4', 'items-end', 'justify-between'])}
      >
        <VolumeSetting
          className={clsm(mobileSVGOpacity)}
          isDisabled={isViewerBanned}
          isExpanded={isVolumeSettingPopupExpanded}
          setOpenPopupIds={setOpenPopupIds}
          updateVolume={updateVolume}
          volumeLevel={volumeLevel}
        />
      </div>
    </div>
  );
};

Controls.propTypes = {
  openPopupIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setOpenPopupIds: PropTypes.func.isRequired
};

Controls.defaultProps = {
  areControlsContained: false,
  isFullscreenEnabled: false
};

export default Controls;
