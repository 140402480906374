import { useRef } from 'react';
import { Outlet } from 'react-router-dom';

import ConfirmationModal from '../../components/ConfirmationModal';
import Notification from '../../components/Notification';
import withSessionLoader from '../../components/withSessionLoader';
import { useResponsiveDevice } from '../../contexts/ResponsiveDevice';
import useCurrentPage from '../../hooks/useCurrentPage';
import { clsm } from '../../utils';

const AppLayoutWithNavbar = () => {
  const { isDefaultResponsiveView, mainRef, isTouchscreenDevice } =
    useResponsiveDevice();

  const currentPage = useCurrentPage();
  const appLayoutRef = useRef();
  const isChannelPage = currentPage === 'channel';
  const isStreamHealthPage = currentPage === 'stream_health';

  return (
    <div
      ref={appLayoutRef}
      className={clsm([
        'flex',
        'min-h-screen',
        'overflow-auto',
        'relative',
        'scroll-smooth',
        'supports-overlay:overflow-overlay',
        isChannelPage && !isTouchscreenDevice && 'md:no-scrollbar'
      ])}
    >
      <main
        ref={mainRef}
        id={`main-app-container${isDefaultResponsiveView ? '' : '-scrollable'}`}
        className={clsm(['absolute', 'bg-neutral-900', 'right-0', 'w-full'])}
      >
        <ConfirmationModal />
        {!isChannelPage && (
          <Notification
            {...(isStreamHealthPage ? { className: 'top-24' } : {})}
          />
        )}
        <Outlet context={{ appLayoutRef }} />
      </main>
    </div>
  );
};

export default withSessionLoader(AppLayoutWithNavbar);
